<template>
    <div class="CalibrationPosition full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="exportPath" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p style="font-weight: 500">{{ $t('report.selectPathExport') }}</p>
                        <v-list>
                            <v-list-item-group
                                v-model="exportPath"
                                color="disiIrisblue"
                                class="list mt-4 mb-8"
                                id="pathmodal-list"
                                mandatory
                            >
                                <v-list-item
                                    v-for="(item) in this.pathlist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="exportPath == ''" @click="$refs.pathdialog.save(exportPath); doExport()">{{ $t('footerButton.save') }}</v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <navigation-left-small noBackButton disabled/>
            <div class="content-area pa-5 flex-grow-1 bg-white">
<!--                Start-->
                <p class="pa-3" v-if="screen === 'start'"> {{ $t('adjCal.securityInfo') }} </p>
                <p class="pa-3" v-if="screen === 'start'"> {{ $t('adjCal.calibrationPositionStartTitle') }}</p>
<!--                lowerStroke-->
                <div v-if="screen === 'lowerStroke'">
                    <p class="pa-3" > {{ $t('adjCal.calibrationPositionLowerStrokeTitle') }}</p>
                    <v-row class="d-flex ">
                        <v-col cols="6" class="pa-0 pt-5">
                            <v-form ref="lowerStrokeForm">
                                <v-row class="d-flex align-center">
                                    <v-col class="col-4">
                                        <p>{{ $t('adjCal.distance') }}</p>
                                    </v-col>
                                    <v-col class="col-5">
                                        <text-input inputName="calibrationPosReport-lowerStroke" @onInputFocus="onInputFocus" onlyNumbers suffix="mm" :rules="[v => !!v || $t('validation.required'), rules.lowerStroke]"/>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </div>
<!--                upperStroke-->
                <div v-if="screen === 'upperStroke'">
                    <v-form ref="upperStrokeForm">
                        <p class="pa-3" > {{ $t('adjCal.calibrationPositionMesureUpperStroke') }}</p>
                        <v-row class="d-flex ">
                            <v-col cols="6" class="pt-0">
                                <v-row class="d-flex align-center">
                                    <v-col class="col-4 pa-0">
                                        <p>{{ $t('adjCal.distance') }}</p>
                                    </v-col>
                                    <v-col class="col-5">
                                        <text-input inputName="calibrationPosReport-upperStroke" @onInputFocus="onInputFocus" onlyNumbers suffix="mm" :rules="[v => !!v || $t('validation.required'), rules.upperStroke]"/>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <p class="pa-3 pt-5"> {{ $t('adjCal.calibrationPositionMesureStrokeHeight') }}</p>
                        <v-row class="d-flex ">
                            <v-col cols="6" class="pt-0">
                                <v-row class="d-flex align-center">
                                    <v-col class="col-4 pa-0">
                                        <p>{{ $t('adjCal.distance') }}</p>
                                    </v-col>
                                    <v-col class="col-5">
                                        <text-input inputName="calibrationPosReport-strokeHeight" @onInputFocus="onInputFocus" onlyNumbers suffix="mm" :rules="[v => !!v || $t('validation.required'), rules.strokeHeight]"/>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
<!--                Finish-->
                <div v-if="screen === 'finish'">
                    <h2 class="pa-3 pb-5" v-if="calibrationPosReport.success">{{ $t('adjCal.calibrationSuccessful') }}</h2>
                    <h2 class="pa-3 pb-5" v-else>{{ $t('adjCal.calibrationFailed') }}</h2>
                    <v-row class="d-flex">
                        <v-col cols="6" class="pa-0 pr-3">
                            <v-row class="d-flex align-center">
                                <v-col class="col-12 pb-2">
                                    <p>{{ $t('adjCal.lowerStrokePoint') }}</p>
                                </v-col>
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.tolerance') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input class="noAction" inputName="calibrationPosReport-lowerStrokeTolerance" readonly suffix="mm"/>
                                </v-col>
                                <v-col class="col-4 ">
                                    <p>{{ $t('adjCal.result') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input class="noAction" inputName="calibrationPosReport-lowerStroke" readonly suffix="mm" :class="{causedError: checkTolerance([calibrationPosReport.lowerStroke, calibrationPosReport.lowerStrokeTolerance])}"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="pa-0 pr-3">
                            <v-row class="d-flex align-center">
                                <v-col class="col-12 pb-2">
                                    <p>{{ $t('adjCal.upperStrokePoint') }}</p>
                                </v-col>
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.tolerance') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input class="noAction" inputName="calibrationPosReport-upperStrokeTolerance" readonly suffix="mm"/>
                                </v-col>
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.result') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input class="noAction" inputName="calibrationPosReport-upperStroke" readonly suffix="mm" :class="{causedError: checkTolerance([calibrationPosReport.upperStroke, calibrationPosReport.upperStrokeTolerance])}"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="pa-0 pt-6 pr-3">
                            <v-row class="d-flex align-center">
                                <v-col class="col-12 pb-2">
                                    <p>{{ $t('adjCal.strokeHeight') }}</p>
                                </v-col>
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.tolerance') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input class="noAction" inputName="calibrationPosReport-strokeHeightTolerance" readonly suffix="mm"/>
                                </v-col>
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.result') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input class="noAction" inputName="calibrationPosReport-strokeHeight" readonly suffix="mm" :class="{causedError: checkStrokeHeight([calibrationPosReport.strokeHeight, calibrationPosReport.strokeHeightTolerance])}"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
<!--            4-->
            <footer-button v-if="screen != 'finish'" ></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="export" :successCheck="showExportSuccess" :progress="showExportProgress" @footerButtonClick="pathmodal = true" :class="{'disabled': !checkUserRights('adjCalExport')}"></footer-button>
<!--            5-->
            <footer-button v-if="screen != 'finish'" buttonFunction="cancel" @footerButtonClick="goToCalibration" ></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress" @footerButtonClick="printReport" :class="{'disabled': !checkUserRights('adjCalPrint')}"></footer-button>
<!--            6-->
            <footer-button v-if="screen === 'start'" buttonFunction="start" @footerButtonClick="startCalibration" ></footer-button>
            <footer-button v-if="screen === 'lowerStroke'" buttonFunction="ok" @footerButtonClick="goOn1" :class="{'disabled': calibrationPosReport.lowerStroke === null || calibrationPosReport.lowerStroke === ''}"></footer-button>
            <footer-button v-if="screen === 'upperStroke'" buttonFunction="ok" @footerButtonClick="goOn2" :class="{'disabled': calibrationPosReport.strokeHeight === null || calibrationPosReport.strokeHeight === '' || calibrationPosReport.upperStroke === null || calibrationPosReport.upperStroke === ''}"></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="ok" @footerButtonClick="goToCalibration"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import TextInput from "@/components/TextInput.vue";
import NavigationLeftSmall from "@/components/NavigationLeftSmall.vue";
import mixins from "@/mixins/mixins";

export default {
    name: 'CalibrationPosition',
    components: {
        NavigationLeftSmall,
        TextInput,
        FooterButton,
    },
    props: {
    },
    data () {
        return{
            screen: 'start',
            calibrationId: 0,
            showPrintProgress: false,
            showPrintSuccess: false,
            showExportProgress: false,
            showExportSuccess: false,
            pathmodal: false,
            exportPath: '',
            rules: {
                lowerStroke: v => {
                    if (v) {
                        return parseFloat(v) <= 50 || `${this.$t('validation.maximum')} 50mm`;
                    } else return true;
                },
                upperStroke: v => {
                    if (v) {
                        return parseFloat(v) <= 30 || `${this.$t('validation.maximum')} 30mm`;
                    } else return true;
                },
                strokeHeight: v => {
                    if (v) {
                        return parseFloat(v) <= 120 || `${this.$t('validation.maximum')} 120mm`;
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'calibrationPosReport',
            'activeStationId',
            'pathlist',
        ]),
    },
    methods:{
        goToCalibration(){
            this.$store.commit('resetCalibrationPosition');
            this.$emit("resetInput");
            this.$router.push('calibration');
        },
        startCalibration(){
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/calibratePosition/start', {id: this.activeStationId}])
                .then(response =>{
                    if (response.status === 200) {
                        this.$store.commit('resetCalibrationPosition');
                        this.$emit("resetInput");
                        this.screen = 'lowerStroke';
                        this.calibrationId = response.data.calibrationId;
                    }
                })
        },
        goOn1(){
            this.$refs.lowerStrokeForm.validate();
            if (this.$refs.lowerStrokeForm.validate()) {
                this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/calibratePosition/lowerStroke', {calibrationId: this.calibrationId, lowerStroke: parseFloat(this.calibrationPosReport.lowerStroke)}])
                    .then(response =>{
                        if (response.status === 200) {
                            this.screen = 'upperStroke'
                        }
                    })
            }
        },
        goOn2(){
            this.$refs.upperStrokeForm.validate();
            if (this.$refs.upperStrokeForm.validate()) {
                this.$store.dispatch('postAxiosSetter', ['/disi/calibration/calibratePosition/measuredDistances', {
                    calibrationId: this.calibrationId,
                    upperStroke: parseFloat(this.calibrationPosReport.upperStroke),
                    strokeHeight: parseFloat(this.calibrationPosReport.strokeHeight)
                    }, 'calibrationPosReport'])
                    .then(response =>{
                        if (response.status === 200) {
                            this.screen = 'finish';
                        }
                    })
            }
        },
        /**
         *
         * @param values: [measured value, tolerance value]
         * @returns {boolean}
         */
        checkTolerance(values){
            let tolerance = values[1];
            let toleranceSign = tolerance.charAt(0);
            let toleranceValue = parseFloat(tolerance.substring(1));
            if (toleranceSign === '<'){
                if (values[0] <= toleranceValue){
                    return false;
                } else {
                    return true;
                }
            } else {
                if (values[0] >= toleranceValue){
                    return false;
                } else {
                    return true;
                }
            }
        },
        /**
         *
         * @param values: [measured value, tolerance value]
         * @returns {boolean}
         */
        checkStrokeHeight(values){
            let tolerance = values[1];
            tolerance = tolerance.split('+/-')
            let maxTolerance = parseFloat(tolerance[0]) + parseFloat(tolerance[1]);
            let minTolerance = parseFloat(tolerance[0]) - parseFloat(tolerance[1]);
            if (values[0] > maxTolerance || values[0] < minTolerance){
                return true;
            } else {
                return false;
            }

        },
        doExport(){
            this.showExportProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/export', {'calibrationId': [this.calibrationId], 'pathId': this.exportPath}])
                .then(response =>{
                    this.showExportProgress = false;
                    if (response.status === 200) {
                        this.showExportSuccess = true;
                        setTimeout(()=>{
                            this.showExportSuccess = false;
                        }, 800)
                    }
                })
        },
        printReport(){
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/print', {'calibrationId': [this.calibrationId]}])
                .then(response =>{
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(()=>{
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                })
        },

        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    beforeRouteLeave (to, from, next) {
        this.$store.commit('resetCalibrationPosition');
        this.$emit("resetInput");
        if(this.screen === 'start' || this.screen === 'finish' ){
            next()
        }else {
            this.$confirm({message: this.$t('alerts.cancelCalibration'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/calibrate/cancel', {'calibrationId': this.calibrationId}]);
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
</style>